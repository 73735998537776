<template>
  <div class="dashboard-widget-settings">
    <el-form
      label-position="left"
      label-width="200px"
      style="width: 500px"
      size="mini"
    >
      <el-form-item label="Ширина колонок">
        <div v-if="localValue.columnsWidth.length < 1">Настройка невозможна (меньше 2 колонок)</div>
        <vue-slider
          v-else
          v-model="localValue.columnsWidth"
          :enable-cross="false"
          :process="sliderColorProcess"
        ></vue-slider>
      </el-form-item>

      <el-form-item label="Скрыть заголовок таблицы">
        <el-switch v-model="localValue.hideHeader"></el-switch>
      </el-form-item>
<!--      <el-form-item label="Скрыть выбор страниц">-->
<!--        <el-switch v-model="localValue.hidePageSelect"></el-switch>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="Скрыть выбор количества записей на страницу">-->
<!--        <el-switch v-model="localValue.hidePageSize"></el-switch>-->
<!--      </el-form-item>-->
      <el-form-item label="Количество записей на страницу">
        <el-slider v-model="localValue.perPage" :min="1" :max="300" show-input input-size="mini"></el-slider>
      </el-form-item>
      <br><br>
      <el-form-item label="Размер текста заголовка">
        <el-slider v-model="localValue.fontSizeHeader" :min="1" :max="30" show-input input-size="mini"></el-slider>
      </el-form-item>
      <el-form-item label="Цвет текста заголовка">
        <el-color-picker v-model="localValue.colorHeader"></el-color-picker>
      </el-form-item>
      <el-form-item label="Padding заголовка">
        <el-slider v-model="localValue.paddingHeader" :min="1" :max="30" show-input input-size="mini"></el-slider>
      </el-form-item>
      <br><br>
      <el-form-item label="Размер текста таблицы">
        <el-slider v-model="localValue.fontSizeBody" :min="1" :max="30" show-input input-size="mini"></el-slider>
      </el-form-item>
      <el-form-item label="Цвет текста таблицы">
        <el-color-picker v-model="localValue.colorBody"></el-color-picker>
      </el-form-item>
      <el-form-item label="Padding таблицы">
        <el-slider v-model="localValue.paddingBody" :min="1" :max="30" show-input input-size="mini"></el-slider>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import Builder from "@/components/report/builder/Builder.vue";
import VQBForm from "@/components/report/builder/VQBForm.vue";
import VQBTableWidgetDataSettings from "@/components/home/dashboard/widgets/VQBTableWidget/data.vue";

export default {
  name: 'VQBTableWidgetSettings',
  components: {VQBForm, Builder},
  props: {
    additionalTabs: {type: Array},
    value: { type: Object, required: true },
  },
  watch: {
    'localValue.vqb.fields.length': function (newVal) {
      this.processSliderDots();
    },
    'localValue.columnsWidth': function (newVal) {
      if( !Array.isArray(newVal) ) {
        this.localValue.columnsWidth = [newVal];
      }
    },
  },
  computed: {
    localValue: {
      get() {return this.value},
      set(value) {this.$emit('input', value)}
    }
  },
  beforeMount() {
    this.processSliderDots(true);
    if( !this.localValue.fontSizeHeader ){this.localValue.fontSizeHeader = 14;}
    if( !this.localValue.fontSizeBody ){this.localValue.fontSizeBody = 14;}
    if( !this.localValue.colorHeader ){this.localValue.colorHeader = '#909399';}
    if( !this.localValue.colorBody ){this.localValue.colorBody = '#606266';}
    if( !this.localValue.paddingHeader ){this.localValue.paddingHeader = 12;}
    if( !this.localValue.paddingBody ){this.localValue.paddingBody = 12;}
  },
  mounted() {
    this.$emit('update:additionalTabs', [...this.additionalTabs, {
      label: 'Настройки данных',
      name: 'data',
      component: VQBTableWidgetDataSettings,
    }]);
  },
  data(){
    return {}
  },
  methods: {
    sliderColorProcess: () => [
      [0, 100, { backgroundColor: '#E4E7ED' }],
    ],

    processSliderDots(initial=false){
      if( initial && this.localValue.columnsWidth ){
        return;
      }

      const vqbFieldsCount = this.localValue?.vqb?.fields?.length || 0;

      this.$set(this.localValue, "columnsWidth", []);

      if( vqbFieldsCount < 2 ){
        this.localValue.columnsWidth = [];
        return;
      }

      const dotsCount = vqbFieldsCount - 1;
      const dotsStep = Math.floor(100 / vqbFieldsCount);

      let currValue = 0;
      for( let i = 0; i < dotsCount; i++ ) {
        currValue += dotsStep;
        this.localValue.columnsWidth.push(currValue);
      }
    }
  }
}
</script>